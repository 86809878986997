import React from "react";
import { getPermissions } from "../../libs/permissions";
import CustomForm from "./CustomForm";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import api from "../../service/api";
import { format, parseISO } from "date-fns";
import AlertSnackBar from "../alertas/AlertSnackBar";
import RequestAudit from "./Audit/requestAudit";
class DialogForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openRequestAudit: false,
      typeForm: null,
      form_layout_json: [],
      form_id: props.form_id,
      layout_id: null,
      id_data_form: props.id_data_form,
      is_editable: null,
      users: [],
      permissions: null,
      has_approved_edit: false,
      ignore_audit: "0"
    };
    this.signatureRef = this.state.form_layout_json
      .filter((form) => form.type == "signature")
      .map(() => React.createRef());
    this.signatureRefTable = this.state.form_layout_json.reduce((acc, item) => {
      if (item.columns) {
        item.columns.forEach((column) => {
          if (column.type === "signature") {
            acc.push(React.createRef());
          }
        });
      }
      return acc;
    }, []);
  }
  componentDidMount() {
    this.getFormsLayout();
    this.getUsers();
    this.getApprovedRequest();
    let permissions = getPermissions()
    if (permissions) {
      this.setState({ permissions: JSON.parse(permissions) })
    }
  }
  getApprovedRequest = () => {
    api.get(`change-requests/${this.state.id_data_form}/check-approved-request`).then(resp => {
      this.setState({ has_approved_edit: resp.data.hasApprovedRequest, is_editable: resp.data.is_editable })
    })
  }
  getUsers = () => {
    const url = window.location.hostname;
    var url_split = url.split(".");
    api.get(`/getDomain?domain=${url_split[0]}`).then((resp_enterprise) => {
      var enterprise_id = resp_enterprise.data.data.result.id;
      api.get(`/getUsers/${enterprise_id}`).then((users) => {
        this.setState({ users: users.data });
      });
    });
  };
  getFormsLayout = async () => {
    this.setState({ loading: true });
    api
      .get("/layout/type", {
        params: {
          form_id: this.state.form_id,
        },
      })
      .then(async (resp) => {
        let form_layout = await JSON.parse(resp.data.options);


        this.setState({
          form_layout_json: form_layout,
          layout_id: resp.data.layout,
          ignore_audit: resp.data.ignore_audit
        });
        this.signatureRefTable = form_layout.reduce(
          (acc, item) => {
            if (item.columns) {
              item.columns.forEach((column) => {
                if (column.type === "signature") {
                  acc.push(React.createRef());
                }
              });
            }
            return acc;
          },
          []
        );
        this.signatureRef = form_layout
          .filter((form) => form.type == "signature")
          .map(() => React.createRef());
        if (this.props.id_data_form != null) {
          this.getDataForms();
        } else {
          this.setState({ loading: false });
        }
      });
  };
  getDataForms = () => {
    api.get(`/data/${this.state.id_data_form}`).then((resp) => {
      var retorno_json = resp.data;
      // this.setState({ form_layout_json: JSON.parse(resp.data), layout_id: resp.data.layout, loading: false })
      if (retorno_json.length > 0) {
        var values_forms = [];
        for (
          let index = 0;
          index < this.state.form_layout_json.length;
          index++
        ) {
          var element = {};
          if (retorno_json[index] == undefined) {
            element = this.state.form_layout_json[index];
          } else {
            element = retorno_json[index];
          }
          const element_layout = this.state.form_layout_json[index];

          if (
            element.type == "input" ||
            element.type == "date" ||
            element.type == "textarea" ||
            element.type == "text-info" ||
            element.type == "select" ||
            element.type == "select-func"
          ) {
            element_layout.value = element.value;
          }

          if (element.type == "table") {
            element_layout.body = element.body;
          }
          if (element.type == "checkbox") {
            element_layout.options = element.options;
          }
          if (element.type == "signature") {
            element_layout.source = element.source;
          }
          values_forms.push(element_layout);
          if (index == this.state.form_layout_json.length - 1) {
            this.setState({ form_layout_json: values_forms, loading: false });
          }
        }
      } else {
        this.setState({ loading: false });
      }
    });
  };
  handleChange = (event) => {
    this.setState({ typeForm: event.target.value });
  };
  handleAdd = () => {
    var new_item = {
      type: this.state.typeForm,
      component: {
        label: this.state.label,
        type_input: this.state.typeForm == "date" ? "date" : "text",
      },
    };

    var items_form = this.state.form_layout_json;
    items_form.push(new_item);
    this.setState({ form_layout_json: items_form });
  };
  handleChangeTextForm = (event, index) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].value = event.target.value;
    this.setState({ form_layout_json: form_datas });
  };
  handleChangeTextTableForm = (event, index, index_component) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].columns[index_component].value = event.target.value;
    this.setState({ form_layout_json: form_datas });
  };
  handleChangeSelectFormTable = (event, index, index_component) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].columns[index_component].value = event.target.value;
    this.setState({ form_layout_json: form_datas });
  };
  handleAddDatasTableForm = (index) => {
    var form_datas = this.state.form_layout_json;
    var colunas = form_datas[index].columns;
    var new_body = [];
    var clear_column = [];
    for (let index_column = 0; index_column < colunas.length; index_column++) {
      const element = colunas[index_column];
      var element_clear = element;
      if (
        element.type != "checkbox" &&
        element.type != "signature" &&
        element.type != "date"
      ) {
        new_body.push(element?.value);
        element_clear.value = null;
      } else if (element.type == "checkbox") {
        var element_checkbox = element.options
          .filter((check) => check.checked == true)
          .map((check) => check.label)
          .join(", ");
        if (element.options.filter((check) => check.type == "obs").length > 0) {
          var element_obs = element.options
            .filter((check) => check.type == "obs")
            .filter((check) => check.value != "" && check.value != undefined)
            .map((check) => check.value)
            .join();
          element_checkbox = element_checkbox.concat(" ").concat(element_obs);
        }
        new_body.push(element_checkbox);
        element_clear.options = element.options.map((check) => {
          return { label: check.label, type: check.type, checked: false };
        });
      } else if (element.type == "signature") {
        new_body.push(element.source);
        element_clear.source = undefined;
      } else if (element.type == "date") {
        var data = element?.value;
        if (element?.value != "" && element?.value != undefined) {
          const dateObject = parseISO(element?.value);
          data = format(dateObject, "dd/MM/yyyy");
        }
        new_body.push(data);
        element_clear.value = null;
      }
      clear_column.push(element_clear);
      if (index_column == colunas.length - 1) {
        form_datas[index].body.push(new_body);
        form_datas[index].columns = clear_column;
        this.setState({ form_layout_json: form_datas });
      }
    }
  };
  handleChangeCheckboxFormMulti = (event, index, index_component) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].options[index_component].checked = event.target.checked;
    // for (let index_for = 0; index_for < form_datas[index].options.length; index_for++) {
    //     if (index_for != index_component) {
    //         form_datas[index].options[index_for].checked = false
    //     }
    // }
    this.setState({ form_layout_json: form_datas });
  };
  handleChangeCheckboxForm = (event, index, index_component) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].options[index_component].checked = event.target.checked;
    for (
      let index_for = 0;
      index_for < form_datas[index].options.length;
      index_for++
    ) {
      if (index_for != index_component) {
        form_datas[index].options[index_for].checked = false;
      }
    }
    this.setState({ form_layout_json: form_datas });
  };
  handleChangeCheckboxFormTableMulti = (
    event,
    index,
    index_component,
    index_component_checkbox
  ) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].columns[index_component].options[
      index_component_checkbox
    ].checked = event.target.checked;
    this.setState({ form_layout_json: form_datas });
  };
  handleChangeCheckboxFormTable = (
    event,
    index,
    index_component,
    index_component_checkbox
  ) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].columns[index_component].options[
      index_component_checkbox
    ].checked = event.target.checked;
    for (
      let index_for = 0;
      index_for < form_datas[index].columns[index_component].options.length;
      index_for++
    ) {
      if (index_for != index_component_checkbox) {
        form_datas[index].columns[index_component].options[
          index_for
        ].checked = false;
      }
    }
    this.setState({ form_layout_json: form_datas });
  };
  handleChangeCheckboxObs = (event, index, index_component) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].options[index_component].value = event.target.value;
    this.setState({ form_layout_json: form_datas });
  };
  handleChangeCheckboxObsTable = (
    event,
    index,
    index_component,
    index_component_checkbox
  ) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].columns[index_component].options[
      index_component_checkbox
    ].value = event.target.value;
    this.setState({ form_layout_json: form_datas });
  };
  handleSelectOptions = (event, index) => {
    var form_datas = this.state.form_layout_json;
    var value_text = event.target.value;
    form_datas[index].value = value_text;
    this.setState({ form_layout_json: form_datas });
  };
  deleteBodyTable = (index, index_component) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].body = form_datas[index].body.filter(
      (body, index) => index != index_component
    );
    this.setState({ form_layout_json: form_datas });
  };

  handleSave = () => {
    this.setState({ loading: true });
    var values_forms = [];
    let identifier = null;
    for (let index = 0; index < this.state.form_layout_json.length; index++) {
      const element = this.state.form_layout_json[index];
      var item_value = {
        type: element.type,
      };
      if (
        element.type == "input" ||
        element.type == "date" ||
        element.type == "textarea" ||
        element.type == "text-info" ||
        element.type == "select" ||
        element.type == "select-func"
      ) {
        item_value.value = element.value;
      }

      if (element.type == "table") {
        let table_identifier = element.columns.filter(items_table => items_table.identifier == true)
        if (table_identifier.length > 0) {
          let index_table_identifier = element.columns.indexOf(table_identifier[0])
          let body_identifiers = element.body.map(body => body[index_table_identifier])
          identifier = body_identifiers.join(",")
        }
        item_value.body = element.body;
      }
      if (element.type == "checkbox") {
        item_value.options = element.options;
      }
      if (element.type == "signature") {
        item_value.source = element.source;
      }
      if (element.identifier) {
        if (element.type == "select-func") {
          identifier = element.value.name;
        } else {
          identifier = element.value;
        }
      }
      values_forms.push(item_value);

      try {
        if (index == this.state.form_layout_json.length - 1) {
          if (this.state.id_data_form == null) {
            api
              .post("/data", {
                data: JSON.stringify(values_forms),
                layout_id: this.state.layout_id,
                user_id: 1,
                identifier: identifier,
              })
              .then((resp) => {
                this.setState({ loading: false });
                this.props.handleCloseSave();
              });
          } else {
            api
              .put(`/data/${this.state.id_data_form}`, {
                data: JSON.stringify(values_forms),
                layout_id: this.state.layout_id,
                user_id: 1,
                identifier: identifier,
              })
              .then((resp) => {
                api.put(`change-requests/${this.state.id_data_form}/finish`).then(resp => {
                  this.setState({ loading: false });
                  this.props.handleCloseSave();
                })
              });
          }
        }
      } catch (error) {
        this.setState({ loading: false });
        console.log(error);
      }
    }
  };
  handleMonitorar = () => {
    this.setState({ loading: true });
    api.get(`/data-form/${this.state.id_data_form}/can-edit-supervisor`).then((resp_exist) => {
      if (resp_exist.data.canEdit) {
        this.setState({ alertMessage: "Já existe um monitor para esse formulário", alertType: 'error', alert: true, loading: false })
      } else {
        api.put(`/supervisor/${this.state.id_data_form}`).then((resp) => {
          this.setState({ loading: false });
          this.props.handleCloseSaveMonitor();
        });
      }
    })

  };
  handleVerificar = () => {
    this.setState({ loading: true });
    api.get(`/data-form/${this.state.id_data_form}/can-edit-checker`).then((resp_exist) => {
      if (resp_exist.data.canEdit) {
        this.setState({ alertMessage: "Já existe um verificador para esse formulário", alertType: 'error', alert: true, loading: false })
      } else {
        api.put(`/checker/${this.state.id_data_form}`).then((resp) => {
          this.setState({ loading: false });
          this.props.handleCloseSaveVerificador();
        });
      }
    })

  };
  handleAjustar = () => {
    var layout = this.state.form_layout_json;
    var array_chekcbox = [];
    for (let index = 0; index < this.state.form_layout_json.length; index++) {
      const element = this.state.form_layout_json[index];
      if (element.type == "checkbox" && index != 0) {
        element.options[0] = { type: "checkbox", label: "SIM", checked: false };
      }

      array_chekcbox.push(element);
      if (index == this.state.form_layout_json.length - 1) {
        api
          .put(`/layouts/5`, {
            options: JSON.stringify(array_chekcbox),
          })
          .then((resp) => {
            this.props.handleCloseSave();
          });
      }
    }
  };
  handleSignature = (index, index_signature) => {
    const signatureData =
      this.signatureRef[index_signature].current.toDataURL();
    const formData = new FormData();
    formData.append("file", signatureData);
    try {
      api
        .post("/storeSignature", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          var form_datas = this.state.form_layout_json;
          form_datas[index].source = resp.data.signature;
          this.setState({ form_layout_json: form_datas });
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleSignatureTable = (index, index_component, index_signature) => {
    const signatureData =
      this.signatureRefTable[index_signature].current.toDataURL();
    const formData = new FormData();
    formData.append("file", signatureData);
    try {
      api
        .post("/storeSignature", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          var form_datas = this.state.form_layout_json;
          form_datas[index].columns[index_component].source =
            resp.data.signature;
          this.setState({ form_layout_json: form_datas });
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleClearSignature = (index_signature) => {
    this.signatureRef[index_signature].current.clear();
  };
  handleClearSignatureTable = (index_signature) => {
    this.signatureRefTable[index_signature].current.clear();
  };
  handleSelectFunc = (index, value) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].value = value;
    this.setState({ form_layout_json: form_datas });
  };
  handleSelectFuncFormTable = (value, index, index_component) => {
    var form_datas = this.state.form_layout_json;
    form_datas[index].columns[index_component].value = value;
    this.setState({ form_layout_json: form_datas });
  };
  handleRequestAudit = () => {
    this.setState({ openRequestAudit: true })
  }
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={true}
        onClose={this.props.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {this.state.openRequestAudit &&
          <RequestAudit
            dataFormId={this.state.id_data_form}
            onClose={() => { this.setState({ openRequestAudit: false }) }}
            onCloseSave={() => {
              this.setState({ alertMessage: "Solicitação enviada com sucesso, aguarde aprovação para realizar edição", alertType: 'success', alert: true, openRequestAudit: false })
              setTimeout(() => {
                this.props.handleClose()
              }, 2000);
            }}
          />}
        {this.state.alert && <AlertSnackBar open={this.state.alert} type={this.state.alertType} message={this.state.alertMessage} onClose={() => { this.setState({ alert: false }) }} />}
        <DialogTitle id="max-width-dialog-title">{this.props.name}</DialogTitle>
        <DialogContent>
          <div>
            {/* <Typography variant="h3" component="h2">
                            {this.props.name}
                        </Typography> */}
            {this.state.loading && (
              <div className={classes.containerProgress}>
                <CircularProgress />
              </div>
            )}
            <CustomForm
              add={true}
              fields={this.state.form_layout_json}
              handleChangeTextForm={this.handleChangeTextForm}
              handleChangeTextTableForm={this.handleChangeTextTableForm}
              handleAddDatasTableForm={this.handleAddDatasTableForm}
              handleChangeCheckboxForm={this.handleChangeCheckboxForm}
              handleChangeCheckboxFormMulti={this.handleChangeCheckboxFormMulti}
              handleChangeCheckboxFormTable={this.handleChangeCheckboxFormTable}
              handleChangeCheckboxFormTableMulti={
                this.handleChangeCheckboxFormTableMulti
              }
              handleChangeCheckboxObs={this.handleChangeCheckboxObs}
              handleSelectOptions={this.handleSelectOptions}
              handleChangeSelectFormTable={this.handleChangeSelectFormTable}
              handleSelectFuncFormTable={this.handleSelectFuncFormTable}
              deleteBodyTable={this.deleteBodyTable}
              handleChangeCheckboxObsTable={this.handleChangeCheckboxObsTable}
              id_data_form={this.state.id_data_form}
              signatureRef={this.signatureRef}
              signatureRefTable={this.signatureRefTable}
              handleSignature={this.handleSignature}
              handleClearSignature={this.handleClearSignature}
              handleSignatureTable={this.handleSignatureTable}
              handleClearSignatureTable={this.handleClearSignatureTable}
              handleSelectFunc={this.handleSelectFunc}
              users={this.state.users}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleClose}
            style={{ backgroundColor: "#DC004E", color: "white" }}
            variant="contained"
          >
            Cancelar
          </Button>
          {(this.state.id_data_form != null && this.state.permissions != null &&
            (this.state.permissions?.name == "ADMINISTRADOR"
              || this.state.permissions?.name == "MONITOR"
              || this.state.permissions?.name == "SUPERVISOR"
            )) &&
            <Button
              onClick={this.handleMonitorar}
              color="secondary"
              variant="contained"
            >
              Monitorar
            </Button>
          }
          {(this.state.id_data_form != null && this.state.permissions != null &&
            (this.state.permissions?.name == "ADMINISTRADOR"
              || this.state.permissions?.name == "VERIFICADOR"
              || this.state.permissions?.name == "SUPERVISOR"
            )) &&
            <Button
              onClick={this.handleVerificar}
              color="secondary"
              variant="contained"
            >
              Verificar
            </Button>
          }
          <Button
            onClick={this.state.ignore_audit == "1" || this.state.id_data_form == null || (this.state.is_editable != "0" && this.state.has_approved_edit == true) ? this.handleSave : this.handleRequestAudit}
            disabled={this.state.loading}
            color="primary"
            variant="contained"
          >
            {this.state.loading && (
              <CircularProgress
                size={28}
                style={{ color: "#3f51b5", marginRight: "8px" }}
              />
            )}
            {this.state.ignore_audit == "1" || this.state.id_data_form == null || (this.state.is_editable != "0" && this.state.has_approved_edit == true) ? "Salvar" : "Solicitar Edição"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 200,
  },
  containerAdd: {
    float: "inline-end",
  },
  containerProgress: {
    textAlign: "center",
  },
});

export default withStyles(styles)(DialogForm);
